import React, { useState, useEffect } from "react";
import FilterableProducts from "../../components/search/FilterableProducts";
import { useRequest } from "../../hooks/useRequest";
import styled from "styled-components";
import Button from "../../components/common/Button";
import SlidingBanner from "../../components/common/SlidingBanner";
import TrendingProductsGrid from "../../components/common/TrendingProductsGrid";

const ClearanceStyle = styled.div`
  padding: 60px 0;
  .blogs__banner {
    margin: 0 0 60px;
    height: 200px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .page__title {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      line-height: 51px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 82.5px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      row-gap: 10px;
    }
  }
`;

const Clearance = () => {
  const [fetchCategories] = useRequest();
  const [fetchBrands] = useRequest();
  const [categoriesData, setCategoriesData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);

  useEffect(() => {
    (async function () {
      const categoryData = await fetchCategories({
        // path: `/category?limit=100&page=1`,
        path: `/category/categories/json-tree`,
      });
      const brandData = await fetchBrands({
        path: `/brand?limit=100&page=1`,
      });
      // setCategoriesData(categoryData?.data?.docs);
      setCategoriesData(categoryData?.data);
      setBrandsData(brandData?.data?.docs);
    })();
    // eslint-disable-next-line
  }, []);

  const [
    fetchSlidingBanner,
    { isLoading: isFetchingSlidingBanner, state: slidingBanners },
  ] = useRequest(`/clearanceslider?limit=10&page=1`);

  useEffect(() => {
    fetchSlidingBanner();
  }, []);

  return (
    <ClearanceStyle>
      <div className="container">
        {/* <div className="blogs__banner">
          <img src="/images/blogs.jpg" alt="blogs" />
          <div className="page__title">
            Group Buy
            <Button title={"Shop Page"}></Button>
          </div>
        </div> */}
        <SlidingBanner
          reqHeight={180}
          onlyBanner={true}
          bannerData={slidingBanners?.data?.docs}
          leftdistance={30}
          loading={isFetchingSlidingBanner || !slidingBanners?.data?.docs}
        />
        <div style={{ marginBottom: 50 }}></div>
        <FilterableProducts
          categoriesData={categoriesData}
          brandsData={brandsData}
          apiPath="/product/groupby"
          title="Clearance Products"
        />
      </div>
      <div className="container">
        <TrendingProductsGrid heading={'Top Selling Clearance Products'} api={'/product/top-selling'} />
      </div>
    </ClearanceStyle>
  );
};

export default Clearance;
