import React, { useEffect, useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { Skeleton } from "@mui/material";
import CategoryCard from "./CategoryCard";
import styled from "styled-components";
import classes from './CategorySlider.module.css'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";

const CategoryStyle = styled.div`
  .categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding: 70px 0;
  }
  .products__related {
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    .categories {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 40px 0;
    }
    .products__related {
      padding: 30px 0 50px;
    }
  }
`;

const CategorySlider = ({ handleCategoryClick }) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [fetchCategories, { isLoading: isFetchingCategories }] = useRequest();
  const [catOtherData, setCatOtherData] = useState({});
  const [arr, setArr] = useState([]);
  const [page, setPage] = useState(1);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const categoryId = searchParams.get('categories[]');

  useEffect(() => {
    (async function () {

      let path = `/category?limit=8&page=${page}&parent=true`;


      const shouldSendCategoryId = categoryId && categoryId !== 'interior' && categoryId !== 'exterior';

      if (shouldSendCategoryId) {
        path = `/category/${categoryId}/category?limit=8&page=${page}&parent=true`
      }

      const categoryData = await fetchCategories({ path });
      setCategoriesData(categoryData?.data?.docs);
      setCatOtherData({ ...categoryData?.data, docs: [] });
      let totalPages = Math.ceil(categoryData?.data?.totalDocs / 8);
      let arrDemo = Array(totalPages).fill("");
      setArr(arrDemo);
    })();
  }, [page, categoryId]);

  const handlePrev = () => {
    if (catOtherData?.hasPrevPage) {
      setPage((prev) => prev - 1);
    }
  };
  const handleNext = () => {
    if (catOtherData?.hasNextPage) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className={classes.parent}>
      <CategoryStyle className="container">
        <div className="categories">
          {isFetchingCategories ? (
            <>
              {Array.from({ length: 8 }, (_, index) => index + 1)?.map(
                (item) => (
                  <Skeleton
                    key={item}
                    variant="rectangular"
                    height={130}
                    style={{ borderRadius: "7.5px" }}
                  />
                )
              )}
            </>
          ) : (
            <>
              {categoriesData?.map((item) => (
                <CategoryCard
                  key={item?.id}
                  bannerUrl={item?.bannerUrl}
                  name={item?.name}
                  type={"text-in-image"}
                  onClick={() => handleCategoryClick(item)}
                />
              ))}
            </>
          )}
        </div>
      </CategoryStyle>
      <button disabled={isFetchingCategories || !catOtherData.hasPrevPage} className={`${classes.btn} ${classes.prev}`} onClick={handlePrev}><MdKeyboardArrowLeft /></button>
      <button disabled={isFetchingCategories || !catOtherData.hasNextPage} className={`${classes.btn} ${classes.next}`} onClick={handleNext}><MdKeyboardArrowRight /></button>
    </div>
  );
};

export default CategorySlider;
