// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioTabs_container__ZXY0A  {
    border: 1px solid red;
    padding: 18px 24px;
    width: 100%;
    border-radius: 4.5px;
    border: 0.75px solid rgba(48, 48, 48, 0.25);
    background: #fff;
    display: flex;
    align-items: center;
    column-gap: 20px;
    cursor: pointer;
}

.RadioTabs_active__4b\\+-p{
    border-color: #ae0000;
}
.RadioTabs_radio_btn__Fs8go {
    width: 15px;
    height: 15px;
    border: 1px solid #ae0000;
    border-radius: 50%;
    padding: 2px;
}
.RadioTabs_radio_btn_active__PDqTv{
    background: #ae0000;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.RadioTabs_details__Zn4wv {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}
.RadioTabs_details__Zn4wv h5 {
    font-size: 14px;
    font-weight: 500;
    color: #ae0000;
}
.RadioTabs_details__Zn4wv p {
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/checkout/tabs/RadioTabs.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,oBAAoB;IACpB,2CAA2C;IAC3C,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;AACnB","sourcesContent":[".container  {\n    border: 1px solid red;\n    padding: 18px 24px;\n    width: 100%;\n    border-radius: 4.5px;\n    border: 0.75px solid rgba(48, 48, 48, 0.25);\n    background: #fff;\n    display: flex;\n    align-items: center;\n    column-gap: 20px;\n    cursor: pointer;\n}\n\n.active{\n    border-color: #ae0000;\n}\n.radio_btn {\n    width: 15px;\n    height: 15px;\n    border: 1px solid #ae0000;\n    border-radius: 50%;\n    padding: 2px;\n}\n.radio_btn_active{\n    background: #ae0000;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n}\n.details {\n    display: flex;\n    flex-direction: column;\n    row-gap: 3px;\n}\n.details h5 {\n    font-size: 14px;\n    font-weight: 500;\n    color: #ae0000;\n}\n.details p {\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RadioTabs_container__ZXY0A`,
	"active": `RadioTabs_active__4b+-p`,
	"radio_btn": `RadioTabs_radio_btn__Fs8go`,
	"radio_btn_active": `RadioTabs_radio_btn_active__PDqTv`,
	"details": `RadioTabs_details__Zn4wv`
};
export default ___CSS_LOADER_EXPORT___;
