import React from "react";
import ProductList from "../product/ProductList";
import { useNavigate } from "react-router-dom";

const TrendingProductsGrid = ({ limit = 10 , heading,api }) => {
  const navigate = useNavigate();
  return (
    <ProductList
      listTitle={heading}
      buttonText={"View All"}
      pagination={true}
      buttonArrow={true}
      apiPath={api}
      handleButtonClick={() => navigate("/search")}
    />
  );
};

export default TrendingProductsGrid;
