import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Searchbar from "../components/forms/search/Searchbar";
import { navLinks } from "../constants/HeaderLinks";
import { Link, useNavigate } from "react-router-dom";
// import { ReactComponent as Cart } from "../assets/cart-icon.png";
import { useAppContext } from "../context/useAppContext";
import { ReactComponent as HamburgerIcon } from "../assets/hamburger.svg";
import { useAuth } from "../hooks/useAuth";
import StyledMask from "../components/common/StyledMask";
import CanadaIcon from "../assets/canada.png";
import { SiGooglelens } from "react-icons/si";
import { toast } from "react-toastify";
const logo = require("../assets/logo.png")
const cartIcon = require("../assets/cart-icon.png");
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
// .header__wrapper {
//   height: 74px;
//   display: flex;
//   align-items: center;
//   gap: 4vw;
//   padding:0 50px 0 0;
//   width: 100%;
//   max-width: 1440px;
//   margin: auto;
// }

const HeaderStyle = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8;
  background-color: #ae0000;
  .header__wrapper {
    height: 74px;
    display: flex;
    align-items: center;
    gap: 2vw;
    padding:0 50px 0 0;
    width: 100%;
    margin: auto;
  }
  .navlink_container{
    position:relative;
  }

  .navlink_child{
    opacity:0;
    background: #ae0000;
    width: 127px;
    // display: flex;
    flex-direction: column;
    gap: 20px;
    padding:20px;
    transition: all .3s ease linear;
    top:25px;
    left:-27px;
        padding-top: 40px;
    position:absolute;
    border-radius:8px;
    display:none;
  }

  .navlink_container:hover .navlink_child{
      opacity:1;
      display: flex;
    }

  nav {
    display: flex;
    gap: 40px;
  }
  a.logo {
    height: 100%;
    background: #fff;
    width: 170px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  a.logo img {
    padding: 6px;
    border-radius: 5px;
    height: auto;
    margin-right: 10px;
    width: 156px;
  }
  .link {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    // line-height: 100%;
    &:last-child {
      margin-bottom: 4px;
    }
  }
  .cart {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .other__links {
    display: flex;
    align-items: center;
    gap: 40px;
    > div {
      cursor: pointer;
    }
  }
  .flag__account__wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .canadian__flag {
    padding: 0 2px;
    height: 18px;
    display: inline-block;
    img {
      outline: 1px solid #fff;
      border-radius: 50%;
    }
  }
  
  .two__liners {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 11.25px;
      display: block;
    }
    
  
    }
    
    @media (max-width: 768px) {
      .header__wrapper {
      padding: 0 24px 0 0;
      height: 60px;
      justify-content: space-between;
    }
    .logo {
      svg {
        width: 140px;
      }
      img {
        width: 140px;
      }
    }
  }
`;



const Blank = styled.div`
  height: 74px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const MobileNavStyle = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  width: 100vw;
  padding: 12px 0px;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
  .link {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    display: block;
    padding: 10px 20px;
    color: #303030;
  }
      .mobile_sb{
      padding: 10px;
    display: flex;
    gap: 10px;
  }
    .padd{
        padding-left: 40px !important;
        color:#ae0000;
        }

  .mobile_sb_button{
      width: 39px;
    height: 39px;
    border: 1px solid black;
    background: black;
    color: white;
    font-size: 17px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
`;

const Header = ({ setIsAuthForm }) => {
  const { isDesktop } = useAppContext();
  const isLoggedIn = useAuth();
  const navigate = useNavigate();
  const [isNavActive, setIsNavActive] = useState(false);

  const [mobLinkActive, setMobLinkActive] = useState(false)
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
  const [file, setFile] = useState(null)

  async function searchFileAndRedirect() {

  }

  const handleFile = e => {
    let newFile = e.target.files[0]
    const fileType = newFile.type;
    const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!validImageTypes.includes(fileType)) {
      toast.error('Invalid file format. Only JPEG, JPG, and PNG are allowed.');
      return;
    }

    setFile(newFile)

    searchFileAndRedirect()
  }

  // useEffect(() => {
  //   if (isNavActive) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "inherit";
  //   }
  //   return () => (document.body.style.overflow = "inherit");
  // }, [isNavActive]);

  return (
    <>
      <Blank />
      <HeaderStyle>
        <div className="header__wrapper">
          <Link to={"/"} className="logo">
            {/* <Logo /> */}
            <img src={logo} alt="vnb logo" style={{
              maxWidth: '130px',
              background: '#fff',
              padding: '6px',
              borderRadius: '5px'
            }} />
          </Link>
          {isDesktop ? (
            <>
              <nav>
                {navLinks?.map((link, index) => {
                  if (link?.title === 'Shop') {
                    return (
                      <div className="navlink_container">

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                          <img src="/images/bars-solid.svg" alt="hamburger" style={{ width: '21px', height: 'auto', filter: 'invert(1)' }} />
                          <Link className="link" key={index} to={link?.link}>
                            {link?.title}
                          </Link>
                        </div>
                        <div className="navlink_child">
                          <Link className="link" key={index} to={'/search'}>
                            {`Shop All`}
                          </Link>
                          <Link className="link" key={index} to={`/search?categories[]=interior`}>
                            {`Interior`}
                          </Link>
                          <Link className="link" key={index} to={`/search?categories[]=exterior`}>
                            {`Exterior`}
                          </Link>
                          <Link className="link" key={index} to={`/clearance`}>
                            {`Clearance`}
                          </Link>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <Link className="link" key={index} to={link?.link}>
                        {link?.title}
                      </Link>
                    )
                  }
                })}
              </nav>
              <Searchbar />
              <div className="other__links">
                <div
                  onClick={() => {
                    !isLoggedIn ? setIsAuthForm(true) : navigate("/account/");
                  }}
                  className="flag__account__wrapper"
                >
                  <span className="canadian__flag">
                    <img src={CanadaIcon} />
                  </span>
                  <div style={{ textTransform: 'capitalize' }} className="two__liners">
                    {isLoggedIn && user ? user?.user?.name : "Sign In"}
                    <span>{isLoggedIn ? 'Welcome' : 'Account'}</span>
                  </div>
                </div>
                <Link to={"/account/purchase-history"} className="two__liners">
                  Returns
                  <span>& Orders</span>
                </Link>
                <Link to={"/cart"} className="cart">
                  {/* <Cart /> */}
                  <img src={cartIcon} alt="vnb logo" style={{
                    maxWidth: '30px',
                    // background: '#fff',
                    filter: 'invert(1)',
                    // padding: '6px',
                    borderRadius: '5px'
                  }} />
                  Cart
                </Link>
                {isLoggedIn && (
                  <Link to={"/logout"} className="link">
                    Logout
                  </Link>
                )}
              </div>
            </>
          ) : (
            isNavActive ?
              <RxCross1 style={{ color: 'white', fontSize: 25 }} onClick={() => setIsNavActive((prev) => !prev)} />
              :
              <HamburgerIcon onClick={() => setIsNavActive((prev) => !prev)} />
          )}
        </div>
      </HeaderStyle>
      {isNavActive && (
        <>
          <StyledMask
            onClick={() => setIsNavActive(false)}
            background={"rgba(0, 0, 0, 0.3)"}
          />
          <MobileNavStyle className="mobile__navigation">
            <nav>
              <div className="mobile_sb">
                <input hidden onChange={handleFile} name="img_search" id="img_search" type="file" />
                <Searchbar showBorder={true} />
                <label htmlFor='img_search' className="mobile_sb_button"><SiGooglelens /></label>
              </div>
              {navLinks?.map((link, index) => (
                link?.title === 'Shop' ?
                  <div className="navlink_container">

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="link" onClick={() => setMobLinkActive(!mobLinkActive)}>
                      Shop
                      {mobLinkActive ? <FaChevronUp /> :
                        <FaChevronDown />}
                    </div>
                    {mobLinkActive &&
                      <div className="navlink_child">
                        <Link className="link padd" key={index} to={'/search'}>
                          {`Shop All`}
                        </Link>
                        <Link className="link padd" key={index} to={`/search?categories[]=interior`}>
                          {`Interior`}
                        </Link>
                        <Link className="link padd" key={index} to={`/search?categories[]=exterior`}>
                          {`Exterior`}
                        </Link>
                        <Link className="link padd" key={index} to={`/clearance`}>
                          {`Clearance`}
                        </Link>
                      </div>}
                  </div> :
                  <Link
                    onClick={() => setIsNavActive(false)}
                    className="link"
                    key={index}
                    to={link?.link}
                  >
                    {link?.title}
                  </Link>
              ))}
            </nav>
            <div className="other__links">
              <div
                onClick={() => {
                  !isLoggedIn ? setIsAuthForm(true) : navigate("/account/");
                  setIsNavActive(false);
                }}
                className="link"
              >
                Account & Lists
              </div>
              <Link
                onClick={() => setIsNavActive(false)}
                to={"/account/purchase-history"}
                className="link"
              >
                Returns
                <span>& Orders</span>
              </Link>
              <Link
                onClick={() => setIsNavActive(false)}
                to={"/cart"}
                className="link"
              >
                Cart
              </Link>
              {isLoggedIn && (
                <Link
                  onClick={() => setIsNavActive(false)}
                  to={"/logout"}
                  className="link"
                >
                  Logout
                </Link>
              )}
            </div>
          </MobileNavStyle>
        </>
      )}
    </>
  );
};

export default Header;
