// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategorySlider_parent__-fr5N{
    position: relative;
}
.CategorySlider_btn__LSiSZ{
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
    background: #ae0000;
    color: white;
    font-size: 34px;
    top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.CategorySlider_prev__U-Rh\\+{
    left: -24px;
}
.CategorySlider_next__qc6b7{
    right: -24px;
}

.CategorySlider_parent__-fr5N button:disabled{
    background: rgb(184, 184, 184);
}`, "",{"version":3,"sources":["webpack://./src/components/category/CategorySlider.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,QAAQ;EACV,2BAA2B;EAC3B,UAAU;AACZ;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".parent{\n    position: relative;\n}\n.btn{\n    position: absolute;\n    width: 50px;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    font-weight: 500;\n    background: #ae0000;\n    color: white;\n    font-size: 34px;\n    top: 50%;\n  transform: translateY(-50%);\n  z-index: 1;\n}\n\n.prev{\n    left: -24px;\n}\n.next{\n    right: -24px;\n}\n\n.parent button:disabled{\n    background: rgb(184, 184, 184);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `CategorySlider_parent__-fr5N`,
	"btn": `CategorySlider_btn__LSiSZ`,
	"prev": `CategorySlider_prev__U-Rh+`,
	"next": `CategorySlider_next__qc6b7`
};
export default ___CSS_LOADER_EXPORT___;
