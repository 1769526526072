import React from "react";
import classes from './RadioTabs.module.css'

const RadioTabs = ({dt,setDT,value,heading,sub_heading}) => {
  return (
    <div onClick={()=>setDT(value)} className={`${classes.container} ${dt === value && classes.active}`}>
      <div className={classes.radio_btn}>
        <div className={`${dt === value && classes.radio_btn_active}`}></div>
      </div>
      <div className={classes.details}>
        <h5>{heading}</h5>
        <p>{sub_heading}</p>
      </div>
    </div>
  );
};

export default RadioTabs;
