import React, { useEffect, useState } from "react";
import Button from "./Button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Image, { defaultImage } from "../Image/Image";

export const BannerStyle = styled.div`
  position: relative;
  max-height: ${({ reqHeight }) => reqHeight}px;
  height: 100%;
  img {
    width: 100%;
    display: block;
    height: ${({ reqHeight }) => reqHeight}px;
    object-fit: cover;
  }
    
  img:nth-child(2){
      display:none;
  }
  .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${({ leftdistance }) => leftdistance}px;
    .title {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 46px;
      text-transform: capitalize;
    }
    .subtitle {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      margin: 15px 0;
      text-transform: capitalize;
    }
    &.dark {
      .title,
      .subtitle {
        color: #000;
      }
    }
  }
  iframe {
    width: 100%;
    height: ${({ reqHeight }) => reqHeight}px;
    border: none;
  }
  @media (max-width: 768px) {
    min-height: 250px;
    img,
    iframe {
      height: 250px;
    }
      img:nth-child(2){
      display:block;
      }
      img:nth-child(1){
      display:none;
      }
    .content {
      top: 60%;
      width: 80%;
      .title {
        font-size: 18px;
        line-height: normal;
      }
      .subtitle {
        font-size: 14px;
        margin: 0 0 15px;
      }
    }
  }
`;

const Banner = ({
  imageSrc,
  buttonLink,
  title,
  textDark = false,
  subtitle,
  onlyBanner = false,
  buttonType,
  titleSize,
  leftdistance,
  buttonTitle,
  contentType = "image",
  reqHeight = '375',
  mobile_src
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    // buttonLink && navigate(buttonLink);
    window.location.href = buttonLink;
  };

  const [isLoad, setIsLoad] = useState(false);

  return (
    <BannerStyle reqHeight={reqHeight} leftdistance={leftdistance || 0}>
      {
        (contentType !== "image" && isLoad) ? (
          <iframe src={imageSrc} onError={() => setIsLoad(false)}
            style={{ display: !isLoad ? 'none' : 'block' }}></iframe>
        ) : (
          <>
            <Image src={imageSrc} alt="Banner" />
            <Image src={mobile_src} alt="Banner" />
            {/* <img  
            onError={(e) => {
              e.target.src =  defaultImage
           }}
            src={mobile_src} className="mobile_banner" alt="" /> */}
            {!onlyBanner && <div className={`content ${textDark ? "dark" : ""}`}>
              <h2 className={`title ${titleSize ? titleSize : ""}`}>{title}</h2>
              <p
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></p>
              {buttonTitle && (
                <Button
                  title={buttonTitle}
                  type={buttonType}
                  onClick={handleClick}
                />
              )}
            </div>}
          </>
        )
      }
    </BannerStyle>
  );
};

export default Banner;
