import { ReactComponent as Headphone } from "../assets/headphone.svg";
import { ReactComponent as Secure } from "../assets/secure.svg";
import { ReactComponent as Shipping } from "../assets/shipping.svg";
import { ReactComponent as Transparent } from "../assets/transparent.svg";

export const data = [
  {
    id: 0,
    title: "Responsive",
    subtitle: "Customer service available 24/7",
    icon: <Headphone />,
  },
  {
    id: 1,
    title: "Secure",
    subtitle: "Certified marketplace since 2024",
    icon: <Secure />,
  },
  {
    id: 2,
    title: "Shipping & Pickup",
    subtitle: "Fast & Reliable",
    icon: <Shipping />,
  },
  {
    id: 3,
    title: "Transparent",
    subtitle: "Price & Availability",
    icon: <Transparent />,
  },
];

export const productIcons = [
  {
    id: 1,
    title: "Shipping & Pickup",
    subtitle: "Available",
    icon: <Shipping />,
  },
  {
    id: 2,
    title:"Trusted Marketplace",
    subtitle: "Secured payments",
    icon: <Secure />,
  },
  {
    id: 3,
    title: "Return Available",
    subtitle: "See return policy",
    icon: <Transparent />,
  },
];
